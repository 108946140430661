export const dummyData = [
  {
    applicationId: 1,
    createdTime: "2024-05-01",
    createdName: "김두두",
    title: "코딩 스터디",
    isOnline: true,
  },
  {
    applicationId: 2,
    createdTime: "2024-05-02",
    createdName: "두두김",
    title: "코딩 스터디",
    isOnline: true,
  },
  {
    applicationId: 3,
    createdTime: "2024-05-03",
    createdName: "두김두",
    title: "코딩 스터디",
    isOnline: false,
  },
  {
    applicationId: 4,
    createdTime: "2024-05-04",
    createdName: "두두두",
    title: "코딩 스터디",
    isOnline: false,
  },
  {
    applicationId: 5,
    createdTime: "2024-05-05",
    createdName: "두두투",
    title: "코딩 스터디",
    isOnline: true,
  }
];

